/*
Copyright 2019 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import React from "react";

class App extends React.Component {
    render (){
      return (
      <div className="App">
        <link rel="stylesheet" href="css/main.css"></link>
          <div className="w3-top" id="home">
            <div className="w3-bar w3-white w3-wide w3-padding w3-card">
              <a href="#home" className="w3-bar-item w3-button"><img src="img/logo1.png" alt="logo" /></a>
              <div id="topNav" className="w3-left">
                <a href="#featured" className="w3-bar-item w3-button">Featured</a>
                <a href="#catalog" className="w3-bar-item w3-button">Catalog</a>
                <a href="#about" className="w3-bar-item w3-button">About</a>
                <a href="#contact" className="w3-bar-item w3-button">Contact</a>
              </div>
            </div>
          </div>
  
          <div className="w3-content w3-padding" style={{maxWidth: 1564}} >

          <div className="w3-container w3-padding-32" id="featured">
            <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Featured</h3>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Softed light</div>
                <img src="img/01.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">High-Key</div>
                <img src="img/02.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/03.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/04.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-container w3-padding-32" id="catalog">
            <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Catalog</h3>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Harded light</div>
                <img src="img/catalog/001.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Harded light</div>
                <img src="img/catalog/002.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Harded light</div>
                <img src="img/catalog/003.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Harded light</div>
                <img src="img/catalog/004.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Softed light</div>
                <img src="img/catalog/005.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Softed light</div>
                <img src="img/catalog/006.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Softed light</div>
                <img src="img/catalog/007.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Softed light</div>
                <img src="img/catalog/008.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">High key</div>
                <img src="img/catalog/009.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">High key</div>
                <img src="img/catalog/010.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">High key</div>
                <img src="img/catalog/011.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">High key</div>
                <img src="img/catalog/012.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
              <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/013.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/014.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/015.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/016.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
              <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/017.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/018.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/019.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Colored light</div>
                <img src="img/catalog/020.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
              <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/021.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/022.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/023.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/024.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
              <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/025.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/026.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/027.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Makeup shoot</div>
                <img src="img/catalog/028.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

          <div className="w3-row-padding">
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
              <div className="w3-display-topleft w3-black w3-padding">Outdoor shoot</div>
                <img src="img/catalog/029.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Outdoor shoot</div>
                <img src="img/catalog/030.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Outdoor shoot</div>
                <img src="img/catalog/031.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
            <div className="w3-col l3 m6 w3-margin-bottom">
              <div className="w3-display-container">
                <div className="w3-display-topleft w3-black w3-padding">Outdoor shoot</div>
                <img src="img/catalog/032.jpg" alt="photo"  style={{width: '100%'}} />
              </div>
            </div>
          </div>

            <div className="w3-container w3-padding-32" id="about">
              <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">About</h3>
              <p>This is a practice of a <strong>A Cloud Guru</strong> course by Joseph Lowery. The webapp is hosted on GCP Cloud run. </p>
              <p>A sample of the  Photoshoot sessions, taken by <strong>parkerpp_</strong>. </p>
              <p><strong>Parker pepe photo studio</strong> is a fictional company created by Jose Meza.</p>
            </div>
  
            <div className="w3-container w3-padding-32" id="contact">
              <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Contact</h3>
              <p>Have a question or comment? Reach out to me via <a href="mailto:josem.meza@outlook.com">email</a> or on <a href="https://www.linkedin.com/in/josemmeza/" target="new">LinkedIn</a>.</p>
            </div>
  
          </div>
  
          <footer className="w3-center w3-black w3-padding-16">
            <p><a href="https://acloudguru.com/" target="new"><img src="img/ps-acg.png" alt="Cloud Guru - A Pluralsight Company" /></a></p>
          </footer>
          
      </div>	
    );
    }
  }
  
  export default App;
  
